import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const GiftingBackground = ({ text, link, className }) => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "homepage" } }) {
          frontmatter {
            secondFullImage {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                  originalName
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData =
        data.markdownRemark.frontmatter.secondFullImage.childImageSharp.fluid
      return (
        <BackgroundImage Tag="section" className={className} fluid={imageData}>
          <div className={"darkened"}>
            <div className={"container"}>
              <a href={link} target="_blank" rel="noopener noreferrer">
                {text}
              </a>
            </div>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

const GiftingBackgroundSection = styled(GiftingBackground)`
  width: 100%;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
`

export default GiftingBackgroundSection
