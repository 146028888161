import React from "react"
import Img from "gatsby-image"

const CategoryImage = ({ imgName, alt = "", critical = false }) => {
  const image = imgName.childImageSharp.fluid
  if (!image) {
    return null
  }
  if (critical) {
    return (
      <Img
        loading="eager"
        fluid={image}
        objectFit="cover"
        objectPosition="50% 50%"
        alt={alt}
        imgStyle={{ height: "500px" }}
        className={"gatsby-category-image"}
      />
    )
  } else {
    return (
      <Img
        fluid={image}
        objectFit="cover"
        objectPosition="50% 50%"
        alt={alt}
        imgStyle={{ height: "500px" }}
        className={"gatsby-category-image"}
      />
    )
  }
}

export default CategoryImage
