import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Row, Col } from "react-bootstrap"
import MarkdownContent from "../components/markdown"
import Img from "gatsby-image"
import CategoryImage from "../components/category-image"
import GiftingBackgroundSection from "../components/gifting-background"
import Helmet from "react-helmet"

const IndexPageTemplate = ({
  firstSquare,
  secondSquare,
  secondLink,
  orderText,
  secondText,
  about,
  aboutImage,
  firstButtonText,
  firstButtonUrl,
  secondButtonText,
  secondButtonUrl,
  thirdButtonText,
  thirdButtonUrl,
}) => (
  <Layout>
    <Seo
      title="The Bandra Cakery - Cake Shop in Mumbai"
      description="Order classic birthday & anniversary cakes online for your loved ones."
    />
    <Helmet>
      <html lang="en" />
      <script type="application/ld+json">{`
		{
			"@context": "https://schema.org",
			"@type": "Bakery",
			"name": "The Bandra Cakery",
			"image": "https://www.thebandracakery.in/style/images/art/about13.jpg",
			"@id": "",
			"url": "https://www.thebandracakery.in/",
			"telephone": "9867181400",
			"menu": "",
			"servesCuisine": "",
			"acceptsReservations": "false",
			"address": {
				"@type": "PostalAddress",
				"streetAddress": "Khar West",
				"addressLocality": "mumbai",
				"postalCode": "400052",
				"addressCountry": "IN"
			},
			"geo": {
				"@type": "GeoCoordinates",
				"latitude": 19.073181,
				"longitude": 72.831208
			},
			"sameAs": [
				"https://www.facebook.com/delcakes.in/",
				"https://twitter.com/deliciaecakes",
				"https://www.instagram.com/thebandracakery.in/"
			]
		}
	`}</script>

      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "The Bandra Cakery",
            "url": "https://www.thebandracakery.in/",
            "logo": "https://www.thebandracakery.in/style/images/logo-light.png",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "9867181400",
              "contactType": "customer service",
              "areaServed": "IN",
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.instagram.com/thebandracakery.in/",
              "https://www.facebook.com/delcakes.in/",
              "https://twitter.com/deliciaecakes"
            ]
          }
        `}
      </script>
      <meta
        property="og:image"
        content="https://www.thebandracakery.in/images/social-preview.png"
      />
      <meta name="p:domain_verify" content="00fa05f980ec56a96b5717746a3982e3" />
      <meta
        name="facebook-domain-verification"
        content="io7qzuq77jtu84tbiv5ltbcgmjqrxm"
      />
    </Helmet>
    <div className={"container-fluid"}>
      <div className={"row py-4 category-row"}>
        <div className={"col-lg-2 col-6 mb-md-3 mb-2 pr-0"}>
          <div
            className={
              "homepage-panel bg-primary h-100 d-flex align-items-center flex-column justify-content-center"
            }
          >
            <strong className="montserrat text-white text-uppercase mb-4">
              {orderText}
            </strong>

            <a
              class="btn btn-secondary btn-rounded"
              href={firstButtonUrl}
              target="_blank"
            >
              {firstButtonText}
            </a>
          </div>
        </div>
        <div className={"col-lg-4 col-6 mb-md-3 mb-2 pl-2 pr-md-1"}>
          <CategoryImage
            loading="eager"
            imgName={firstSquare}
            alt="Wedding Cakes"
          />
        </div>
        <div className={"col-lg-4 col-6 mb-md-3 mb-2 pr-0 pl-md-1"}>
          <CategoryImage loading="eager" imgName={secondSquare} alt="Gifting" />
        </div>
        <div className={"col-lg-2 col-6 mb-md-3 mb-2 pl-2"}>
          <div className="homepage-panel bg-primary h-100 d-flex align-items-center flex-column justify-content-center">
            <a
              class="btn btn-secondary btn-rounded mb-4 mt-3 mt-md-0"
              href={secondButtonUrl}
            >
              {secondButtonText}
            </a>
            <a class="btn btn-secondary btn-rounded mb-4" href={thirdButtonUrl}>
              {thirdButtonText}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div className={"light-bg py-5"}>
      <div className={"container-fluid"}>
        <h5 className={"my-md-5 my-3 text-center"}>ABOUT THE BANDRA CAKERY</h5>
        <div className={"container"}>
          <Row>
            <Col lg="5" className={"mb-3 mb-md-0"}>
              <Img
                fluid={aboutImage.childImageSharp.fluid}
                alt="About Deliciae"
              />
            </Col>
            <Col lg="7">
              <MarkdownContent content={about} className={"montserrat"} />
              <div className={"social-icons"} style="display: none;">
                <a
                  href="https://www.facebook.com/delcakes.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={["fab", "facebook-f"]} />
                </a>
                <a
                  href="https://www.instagram.com/thebandracakery.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={["fab", "instagram"]} />
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div class="mt-5">
        <GiftingBackgroundSection
          text={secondText}
          link={secondLink}
          className={
            typeof window !== "undefined" &&
            window.navigator.userAgent &&
            /iPad|iPhone|iPod/.test(window.navigator.userAgent)
              ? "gifting-message"
              : "gifting-message no-ios"
          }
        />
      </div>
    </div>
  </Layout>
)

IndexPageTemplate.propTypes = {
  about: PropTypes.string,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <IndexPageTemplate
      firstSquare={frontmatter.firstSquare}
      secondSquare={frontmatter.secondSquare}
      secondText={frontmatter.secondText}
      secondLink={frontmatter.secondLink}
      orderText={frontmatter.orderText}
      about={frontmatter.about}
      aboutImage={frontmatter.aboutImage}
      firstButtonText={frontmatter.firstButtonText}
      firstButtonUrl={frontmatter.firstButtonUrl}
      secondButtonText={frontmatter.secondButtonText}
      secondButtonUrl={frontmatter.secondButtonUrl}
      thirdButtonText={frontmatter.thirdButtonText}
      thirdButtonUrl={frontmatter.thirdButtonUrl}
    />
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage
export const query = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "homepage" } }) {
      frontmatter {
        about
        secondText
        secondLink
        firstSquare {
          childImageSharp {
            fluid(maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
          }
        }
        secondSquare {
          childImageSharp {
            fluid(maxWidth: 768) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
          }
        }
        aboutImage {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
          }
        }
        orderText
        firstButtonText
        secondButtonText
        thirdButtonText
        firstButtonUrl
        secondButtonUrl
        thirdButtonUrl
      }
    }
  }
`
